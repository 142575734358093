<template>
    <section>
        <div class="navbar">
            <div class="d-middle w-100">
                <div class="col-auto">
                    <img src="/img/auth/logo_blanco.svg" style="max-height:100%;max-width:140px;" />
                </div>
                <router-link :to="{name: 'login'}" class="ml-auto">
                    <div class="col-auto text-white f-600 ml-auto d-middle-center f-17">
                        Regresar al inicio 
                    </div>
                </router-link>
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 82px);">
            <div class="row mx-0 align-items-center justify-center">
                <div class="col-6 px-0 text-center py-3">
                    <img :src="img_construccion" class="obj-cover" style="max-width:100%;height:380px;" />
                    <p class="text-general f-18 text-center f-600 py-3">
                        Te pedimos disculpas por el retraso. Como toda construcción, está llevando tiempo. Pero ten paciencia que ya pronto estaremos contigo.
                    </p>
                </div>
            </div>
            <div class="row mx-0 justify-center">
                <div class="col-xl-3 text-center">
                    <div class="d-middle-center" style="height:130px;">
                        <img :src="img_alerta" class="obj-cover my-2" style="max-width:100%;height:100px;" />
                    </div>
                    <p class="text-general f-18 text-center f-600">
                        ¿Por qué no puedo navegar por esta página web?
                    </p>
                    <p class="text-general f-14 mt-3">
                        Queremos llevar la misma experiencia de la aplicación móvil a un espacio web, así que estamos trabajando para ello, te agradecemos por tenernos paciencia y que sigas usando nuestra app leeche.
                    </p>
                </div>
                <div class="col-xl-3 text-center">
                    <div class="d-middle-center" style="height:130px;">
                        <img :src="img_timer" class="obj-cover my-2" style="max-width:100%;height:100px;" />
                    </div>
                    <p class="text-general f-18 text-center f-600">
                        ¿Cómo sabré cuándo el sitio web estará listo?
                    </p>
                    <p class="text-general f-14 mt-3">
                        Sabemos lo importante que es para ti, así que una vez nuestro sitio este listo te lo informaremos vía correo electrónico. Si aun no estás inscrito te invitamos a descargar nuestra app leeche en playstore y app store.
                    </p>
                </div>
                <div class="col-3 text-center">
                    <div class="d-middle-center" style="height:130px;">
                        <img :src="img_productos" class="obj-cover my-2" style="max-width:100%;height:100px;" />
                    </div>
                    <p class="text-general f-18 text-center f-600">
                        ¿En qué otro lugar puedo comprar los productos?
                    </p>
                    <p class="text-general f-14 mt-3">
                        Tranquilo, aún puedes seguir pidiendo tus productos favoritos por la app de leeche.
                    </p>
                    <div class="d-flex mx-0 mt-2 justify-content-center py-2 align-items-center">
                        <a href="https://play.google.com/store/apps/details?id=com.grupobienpensado.leeche_client">
                            <img :src="url_play_store" class="obj-cover" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.grupobienpensado.leeche_client">
                            <img :src="url_google_play" class="obj-cover" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    layout: 'postulacion',
    data(){
        return {
            img_construccion: '/img/auth/construccion.svg',
            img_alerta: '/img/auth/alert.svg',
            img_timer: '/img/auth/timer.svg',
            img_productos: '/img/auth/productos.svg',
            url_play_store: '/img/auth/playstore.png',
            url_google_play: '/img/auth/appstore.png'
        }
    }
}
</script>

<style lang="scss" scoped>
a{
    text-decoration: none !important;;
}
.navbar{
    min-height: 80px;
    background-color: var(--color-general) !important;
    border-bottom-left-radius: 12px !important;
}
</style>